import React from "react"

const defaultState = { 
  items: [], 
  products: [],
  projectType: null,
  formData: {
    firstname: "",
    lastname: ".",
    email: "",
    phone: "",
    suburb: "",
    message: "",
  },
  description: "",
  amount: 0,
  total: 0,
  submitted: false
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

export const CalculatorContext = React.createContext(defaultState)

class CalculatorProvider extends React.Component {
  state = {
    ...defaultState
  }

  UNSAFE_componentWillMount() {
    this.updateState();
  }

  resetState = () => {
    this.setState(defaultState);
    localStorage.setItem("items", JSON.stringify(defaultState.items));
    localStorage.setItem("projectType", JSON.stringify(defaultState.projectType));
    localStorage.setItem("total", JSON.stringify(defaultState.total));
    localStorage.setItem("amount", JSON.stringify(defaultState.amount));
    localStorage.setItem("formData", JSON.stringify(defaultState.formData));
    localStorage.setItem("description", JSON.stringify(defaultState.description));
    localStorage.setItem("submitted", JSON.stringify(defaultState.submitted));
  }

  updateState = () => {
    const items = JSON.parse(localStorage.getItem("items")) ?? [];
    const projectType = JSON.parse(localStorage.getItem("projectType"));
    const amount = JSON.parse(localStorage.getItem("amount"));
    const total = JSON.parse(localStorage.getItem("total"));
    const description = JSON.parse(localStorage.getItem("description"));
    const submitted = JSON.parse(localStorage.getItem("submitted"));
    this.setState({ items, amount, total, description, projectType, submitted });
  }

  setProducts = (products) => {
    const total = this.getTotal(this.state.items, products);
    const amount = this.getFormattedAmount(total);
    localStorage.setItem("total", JSON.stringify(total));
    localStorage.setItem("amount", JSON.stringify(amount));
    this.setState(oldState => ({ ...oldState, total, amount, products }));
  }

  postItems = (items) => {
    const total = this.getTotal(items, this.state.products);
    const amount = this.getFormattedAmount(total);
    const description = this.getDescription(items);
    localStorage.setItem("items", JSON.stringify(items));
    localStorage.setItem("total", JSON.stringify(total));
    localStorage.setItem("amount", JSON.stringify(amount));
    localStorage.setItem("description", JSON.stringify(description));
  }

  setItems = (items) => {
    const total = this.getTotal(items, this.state.products);
    const amount = this.getFormattedAmount(total);
    const description = this.getDescription(items);
    this.setState(oldState => ({ ...oldState, items, total, amount, description }));
    localStorage.setItem("items", JSON.stringify(items));
    localStorage.setItem("total", JSON.stringify(total));
    localStorage.setItem("amount", JSON.stringify(amount));
    localStorage.setItem("description", JSON.stringify(description));
  }

  setProjectType = (projectType) => {
    this.setState(oldState => ({ ...oldState, projectType }));
    localStorage.setItem("projectType", JSON.stringify(projectType));
  }

  setFormData = (formData) => {
    this.setState(oldState => ({ ...oldState, formData }));
  }

  setFormSubmitted = () => {
    this.setState(oldState => ({ ...oldState, submitted: true }))
    localStorage.setItem("submitted", JSON.stringify(true));
  }

  getSizeInMeters = (size, units) =>
    units === "cm" ? size / 100 : size / 1000;

  getSizeInCM = (size, units) =>
    units === "cm" ? size : size / 10;

  getArea = (width, height, units) => this.getSizeInMeters(width, units) * this.getSizeInMeters(height, units);

  getTotal = (items, products) => {
    return items.reduce((total, item) => {
      const area = this.getArea(item.width, item.height, item.units);
      const price = products.find((x) => x.id === item.id)?.price ?? 0;
      const cost = area * price * item.quantity;
      return (total += cost);
    }, 0);
  }

  getFormattedAmount = (total) => formatter.format(total)

  getDescription = (items) => {
    return items.map((item) => {
      const area = this.getArea(item.width, item.height, item.units);
      const widthInCM = this.getSizeInCM(item.width, item.units);
      const heightInCM = this.getSizeInCM(item.height, item.units);
        return `${item.quantity}x ${item.name} (${widthInCM.toFixed(2)} x ${heightInCM.toFixed(2)} cm = ${area.toFixed(2)}sqm)`
    })
    .join("\n")
  }

  render() {
    const { items, description, total, amount, projectType, formData, submitted } = this.state;
  
    return (
      <CalculatorContext.Provider 
        value={{ 
          setProducts: this.setProducts,
          items, 
          setItems: this.setItems, 
          projectType, 
          setProjectType: this.setProjectType,
          formData,
          setFormData: this.setFormData,
          description,
          total,
          amount,
          resetState: this.resetState,
          submitted,
          setFormSubmitted: this.setFormSubmitted,
          updateState: this.updateState,
          postItems: this.postItems
        }}
      >
        {this.props.children}
      </CalculatorContext.Provider>
    )
  }
}

export { CalculatorProvider }